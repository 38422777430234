import { useEffect, useState } from "react";
import { EventEmitter } from "../../utils/event-emitter";
import Events from "../../../const/events";
import ContactApi from "../../../api/contact-api";
import OutsideClickHandler from "react-outside-click-handler";
import {QRCodeSVG} from 'qrcode.react';

const QRViewer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [qrData, setQrData] = useState("");
  const [contactId, setContactId] = useState("");
  const [contactName, setContactName] = useState("");

  const _loadQRCode = async (contactId) => {
    try {
      const response = await ContactApi.private.getRoomLink(contactId);
      console.log('response :>> ', response);
      setQrData(response?.data?.data);
      setIsVisible(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    EventEmitter.subscribe(Events.SHOW_QR_CODE_POPUP, ({id, title}) => {
      setContactId(id);
        setContactName(title);
      _loadQRCode(id);
    });
    return () => {
      EventEmitter.cancelAll(Events.SHOW_QR_CODE_POPUP);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="modal-wrapper">
      <div
        className="modal fade show"
        id="newMessageModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="newMessageModalTitle"
        aria-hidden="false"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsVisible(false);
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title m-0" id="newMessageModalTitle">
                  Quick Connect to {contactName}
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsVisible(false)}
                ></button>
              </div>
              <div className="modal-body text-center">
                {qrData && (
                  <>
                    {/* QR Code */}
                    <QRCodeSVG value={qrData} size={200} level={"H"} />

                    {/* Link */}
                    <div className="mt-3">
                      <a href={qrData} target="_blank" rel="noopener noreferrer">
                        {qrData}
                      </a>
                    </div>
                  </>
                )}
              </div>
              <div className="modal-footer d-flex align-items-center justify-content-between">
                <button
                  type="button"
                  className="btn btn-soft-danger btn-sm"
                  data-bs-dismiss="modal"
                  onClick={() => setIsVisible(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </div>
  );
};

export default QRViewer;

import React, { useState } from "react";
import AuthHelper from "../../helpers/auth-helper/auth-helper";
import router from "../../../../shared/top-level-declarations/history";
import "./login-page.scss";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async () => {
    setLoading(true);
    setError("");

    try {
      await AuthHelper.login(email, password); // Call login through AuthHelper
      // Navigate to the dashboard after successful login
      router.navigate("/whatsapp-chats");
    } catch (err) {
      setError(err.message); // Handle error from AuthHelper
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Log In page */}
      <div className="container-fluid login-bg">
        <div className="row vh-100 d-flex justify-content-center">
          <div className="col-12 align-self-center">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 mx-auto">
                  <div className="card">
                    <div className="card-body p-0 auth-header-box">
                      <div className="text-center p-3">
                        <a href="index.html" className="logo logo-admin">
                          <img
                            src="assets/images/relai-logo-light-bg-transparent.png"
                            height={50}
                            alt="logo"
                            className="auth-logo"
                          />
                        </a>
                        <h4 className="mt-3 mb-1 fw-semibold text-white font-18">
                          Let's Get Started Unikit
                        </h4>
                        <p className="text-muted  mb-0">
                          Sign in to continue to Doxa.
                        </p>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <form
                        className="my-4"
                        onSubmit={(e) => e.preventDefault()} // Prevent form submission
                      >
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="email">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        {/*end form-group*/}
                        <div className="form-group">
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        {/*end form-group*/}
                        <div className="form-group row mt-3">
                          <div className="col-sm-6">
                            <div className="form-check form-switch form-switch-success">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="customSwitchSuccess"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customSwitchSuccess"
                              >
                                Remember me for 30 days
                              </label>
                            </div>
                          </div>
                          {/*end col*/}
                          <div className="col-sm-6 text-end">
                            {/* <a
                              href="/forgot-password"
                              className="text-muted font-13"
                            >
                              <i className="dripicons-lock" /> Forgot password?
                            </a> */}
                          </div>
                          {/*end col*/}
                        </div>
                        {/*end form-group*/}
                        <div className="form-group mb-0 row">
                          <div className="col-12">
                            <div className="d-grid mt-3">
                              <button
                                className="btn btn-success"
                                type="button"
                                onClick={handleLogin} // Trigger login on button click
                                disabled={loading} // Disable button while loading
                              >
                                {loading ? "Logging in..." : "Log In"}{" "}
                                <i className="fas fa-sign-in-alt ms-1" />
                              </button>
                            </div>
                          </div>
                          {/*end col*/}
                        </div>
                        {/*end form-group*/}
                        {error && (
                          <div className="errorMessage mt-2">
                            <p className="text-danger">{error}</p>
                          </div>
                        )}
                        {/* Display error message if login fails */}
                      </form>
                    </div>
                    {/*end card-body*/}
                  </div>
                  {/*end card*/}
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
            {/*end card-body*/}
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </div>
      {/*end container*/}
    </>
  );
};

export default LoginPage;

// AppRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import routes from "./routes";
import ProtectedRoute from "./protected-route/protected-route";
import PublicRoute from "./public-route/public-route";
import DashboardLayout from "../shared/components/dashboard-layout/dashboard-layout";

const AppRoutes = () => {
  return (
    <Routes>
      {routes.map(({ path, component: Component, isProtected }) => {
        if (isProtected) {
          return (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute>
                  <DashboardLayout>
                    <Component />
                  </DashboardLayout>
                </ProtectedRoute>
              }
            />
          );
        }

        return (
          <Route
            key={path}
            path={path}
            element={
              <PublicRoute>
                <Component />
              </PublicRoute>
            }
          />
        );
      })}
    </Routes>
  );
};

export default AppRoutes;

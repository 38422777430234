import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./add-edit-user-modal.scss";
import ManageMemberUtils from "../../utils/manage-member-utils";

const AddEditUserModal = ({ show, onClose, user, onUserUpdated }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    phone: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || "",
        email: user.email || "",
        password: "",
        role: user.role || "",
        phone: user.phone || "",
      });
    } else {
      setFormData({
        name: "",
        email: "",
        password: "",
        role: "",
        phone: "",
      });
    }
    setError("");
  }, [user]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { name, email, password, role } = formData;
    if (!name) return "Name is required.";
    if (!email) return "Email is required.";
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      return "Invalid email format.";
    if (!role) return "Role is required.";
    if (!user && !password)
      return "Password is required when creating a new user.";
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorMsg = validateForm();
    if (errorMsg) {
      setError(errorMsg);
      return;
    }

    try {
      setError("");
      if (user) {
        // Edit mode
        await ManageMemberUtils.updateMember({ ...user, ...formData });
        console.log("User updated:", formData);
      } else {
        // Add mode
        await ManageMemberUtils.addNewMember(formData);
        console.log("New user added:", formData);
      }

      onUserUpdated(); // Callback to refresh the user list in the parent component
      onClose(); // Close the modal
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <div
      className={`custom-modal-overlay ${show ? "show" : ""}`}
      role="dialog"
      aria-labelledby="newUserAddorEditModalTitle"
      aria-hidden={!show}
    >
      <div className="custom-modal-content">
        <div className="modal-header">
          <h6 className="modal-title" id="newUserAddorEditModalTitle">
            {user ? "Edit User" : "Add New User"}
          </h6>
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
          ></button>
        </div>
        <div className="modal-body">
          <div className="modal-image-section">
            <img
              src="assets/images/small/add-user.svg"
              alt="User Illustration"
              className="img-fluid"
            />
          </div>
          <div className="modal-form-section">
            {error && <div className="alert alert-danger">{error}</div>}
            <h5 className="modal-subtitle">
              {user
                ? `Edit Details for user ${user.name}`
                : "Enter Details for New User"}
            </h5>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled={!!user}
                />
              </div>
              {!user && (
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
              )}
              <div className="form-group">
                <label>Role</label>
                <select
                  className="form-select"
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  required
                >
                  <option value="">-- Select a role --</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                  <option value="user">User</option>
                </select>
              </div>
              <div className="form-group">
                <label>Phone (Optional)</label>
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-soft-danger"
            onClick={onClose}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            {user ? "Save Changes" : "Add User"}
          </button>
        </div>
      </div>
    </div>
  );
};

AddEditUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object,
  onUserUpdated: PropTypes.func.isRequired, // Callback to refresh user list
};

export default AddEditUserModal;

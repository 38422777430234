import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import Events from "../../../../const/events";
import "./twilio-sandbox-warning-popup.scss";
const TwilioSandboxWarningPopup = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    const _registerEvents = () => {
      EventEmitter.subscribe(Events.SHOW_TWILIO_SANDBOX_WARNING, () => {
        setPopupVisible(true);
      });
      EventEmitter.subscribe(Events.HIDE_TWILIO_SANDBOX_WARNING, () => {
        setPopupVisible(false);
      });
    };
    const _unregisterEvents = () => {
      EventEmitter.cancelAll(Events.SHOW_TWILIO_SANDBOX_WARNING);
      EventEmitter.cancelAll(Events.HIDE_TWILIO_SANDBOX_WARNING);
    };
    _registerEvents();
    return () => {
      _unregisterEvents();
    };
  }, []);

  if (!isPopupVisible) {
    return null;
  }

  return (
    <div className="modal-wrapper">
      <div
        className="modal fade show"
        id="twilioSandboxWarningModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="twilioSandboxWarningModalTitle"
        aria-hidden="false"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <OutsideClickHandler
            onOutsideClick={() => {
              setPopupVisible(false);
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h6
                  className="modal-title m-0"
                  id="twilioSandboxWarningModalTitle"
                >
                  Twilio Sandbox Active
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setPopupVisible(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col-lg-5 text-center align-self-center">
                    <img
                      src="assets/images/sandbox-qr.png"
                      alt="Scan QR to Start"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-12">
                        <h4 className="text-dark text-center mb-4">
                          Start a Conversation by Scanning the QR Code
                        </h4>
                      </div>
                      <div className="col-12">
                        <p className="text-muted">
                          Currently, this project is using the Twilio Sandbox.
                          To initiate a conversation, please scan the QR code.
                          Keep in mind that message templates are not supported
                          in sandbox mode.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => setPopupVisible(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </div>
  );
};

export default TwilioSandboxWarningPopup;

import React from "react";
import { Provider as StoreProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { persistor, store } from "../../redux/store";
// import AppInitProvider from "./app-init-provider";
import FirebaseProvider from "./firebase-provider/firebase-provider";
import AppInitProvider from "./app-init-provider/app-init-provider";

const TopLevelProviders = ({ children }) => {
  return (
    <>
      <FirebaseProvider>
        <StoreProvider store={store}>
          <PersistGate loading={<div>loading</div>} persistor={persistor}>
            <AppInitProvider>{children}</AppInitProvider>
          </PersistGate>
        </StoreProvider>
      </FirebaseProvider>
    </>
  );
};

export default TopLevelProviders;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AuthHelper from "../../../features/authentication/helpers/auth-helper/auth-helper";

/*
    AppInitProvider is responsible for initializing the app state,
    specifically ensuring the user's tokens are refreshed before loading the rest of the app.
*/

const AppInitProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false); // Track whether initialization is complete
  const refreshToken = useSelector((state) => state.userStore?.refreshToken); // Get the refresh token from Redux
  console.log('refreshToken :>> ', refreshToken);
  useEffect(() => {
    // Function to fetch a new access token using the refresh token
    const initializeApp = async () => {
      try {
        console.log('refreshToken :>> ', refreshToken);
        if (refreshToken) {
          // If a refresh token exists, fetch a new access token
          await AuthHelper.refreshToken(); // This refreshes the token and stores it in Redux
        } else {
          console.warn("No refresh token found. Logging out user."); // Log a warning if no refresh token exists
          // AuthHelper.logout(); // If no refresh token exists, log out the user
        }
      } catch (error) {
        console.error("Error during token refresh:", error);
        // Handle any errors (e.g., log out the user if the refresh token is invalid)
        AuthHelper.logout(); // If token refresh fails, log out the user
      } finally {
        setIsInitialized(true); // Mark initialization as complete
      }
    };

    initializeApp(); // Call the initialization function when the component mounts
  }, []);

  // Don't render the app's children until initialization is complete
  if (!isInitialized) {
    return <div>Loading...</div>; // Show a loading state while initializing
  }

  return <>{children}</>; // Once initialized, render the child components
};

export default AppInitProvider;

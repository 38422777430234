import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the thread
const initialState = {
    isLoaderActive: false,
    allThreads: [],
    selectedThread: null,
    searchText: "",
    threadListActiveView: "all",
    pagination: {
        limit: 50,
        totalPages: 1,
        page: -1,
        hasNextPage: true,
    },
};

// Create a slice for user-related state
const threadSlice = createSlice({
  name: "threadStore",
  initialState,
  reducers: {
    setLoaderActive: (state, action) => {
        state.isLoaderActive = action.payload;
    },
    setAllThreads: (state, action) => {
        state.allThreads = action.payload;
    },
    setSelectedThread: (state, action) => {
        state.selectedThread = action.payload;
    },
    setSearchText: (state, action) => {
        state.searchText = action.payload;
    },
    setThreadListActiveView: (state, action) => {
        state.threadListActiveView = action.payload;
    },
    setPagination: (state, action) => {
        state.pagination = action.payload;
    }
  },
});

// Export the actions directly for usage without dispatching manually
const { 
    setLoaderActive, 
    setAllThreads, 
    setSelectedThread, 
    setSearchText, 
    setThreadListActiveView,
    setPagination
 } = threadSlice.actions;

// Create a ThreadStore object that automatically dispatches actions
export const ThreadStoreActions = {
    setLoaderActive,
    setAllThreads,
    setSelectedThread,
    setSearchText,
    setThreadListActiveView,
    setPagination
};

// Export the reducer to be used in the store
export const ThreadStoreReducer = threadSlice.reducer;

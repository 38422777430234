// message-api.js
import {
  ProtectedHttpClient,
  PublicHttpClient,
} from "../shared/clients/http-client";

const MessageApi = {
  private: {
    getMessages: ({ threadId, page, limit }) =>
      ProtectedHttpClient.post(`/messages?page=${page}&limit=${limit}`, {
        _thread: threadId,
      }), // Requires token
    getAllTemplates: (orgId) =>
      ProtectedHttpClient.get(`/organization/${orgId}/twilio-templates`), // Requires token
    sendMessage: ({ threadId, message, messageType }) =>
      ProtectedHttpClient.post(`/thread/${threadId}/send-message`, {
        msgType: messageType,
        message,
      }), // Requires token
    sendTemplateMessage: ({ threadId, payload }) =>
      ProtectedHttpClient.post(
        `/thread/${threadId}/send-template-message`,
        payload
      ), // Requires token
  },
};

export default MessageApi;

import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the user
const initialState = {
  userInfo: null, // Stores user details (id, email, role, etc.)
  accessToken: null, // Stores access token
  refreshToken: null, // Stores refresh token
  isAuthenticated: false, // Indicates if the user is authenticated
  loading: false, // For tracking the authentication process
  error: null, // Stores any error messages
};

// Create a slice for user-related state
const userSlice = createSlice({
  name: "userStore",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      const { user, accessToken, refreshToken } = action.payload; // Extract user, accessToken, and refreshToken
      state.loading = false;
      state.userInfo = user; // Store user details in state
      state.accessToken = accessToken; // Store access token
      state.refreshToken = refreshToken; // Store refresh token
      state.isAuthenticated = true;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.userInfo = null;
      state.accessToken = null; // Clear access token
      state.refreshToken = null; // Clear refresh token
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    updateTokens: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken; // Update access token
      state.refreshToken = refreshToken; // Update refresh token (if provided)
    },
  },
});

// Export the actions directly for usage without dispatching manually
const { loginRequest, loginSuccess, loginFailure, logout, clearError, updateTokens } = userSlice.actions;

// Create a UserStore object that automatically dispatches actions
export const UserStoreActions = {
  loginRequest,
  loginSuccess,
  loginFailure,
  logout,
  clearError,
  updateTokens, // Added updateTokens to export actions
};

// Export the reducer to be used in the store
export const UserStoreReducer = userSlice.reducer;

import moment from "moment";
import ApiCalls from "../../api";
import { store } from "../../redux/store";
import { ThreadStoreActions } from "../../redux/thread-store";
import MessageHelper from "../message/message-helper";
import wavFile from "../../features/notification/relai-notification.wav";

const PrivateMethods = {};

const ThreadHelper = {
  // Public
  // This should be called when the search text changes
  // It will reset the pagination and fetch the threads list
  loadThreadsList: async () => {
    const { threadStore } = store.getState();
    const { searchText } = threadStore;
    // clear the threads list
    store.dispatch(ThreadStoreActions.setAllThreads([]));
    let threads = [];
    try {
      ThreadHelper.showLoader();
      const response = await ApiCalls.thread.private.getThreads({
        searchText,
        page: 1,
        limit: 50,
      });
      console.log("response :>> ", response);
      if (response?.data?.data) {
        const { docs, totalDocs, limit, page, totalPages, hasNextPage } =
          response.data.data;
        store.dispatch(
          ThreadStoreActions.setPagination({
            limit,
            totalPages,
            page,
            hasNextPage,
            totalDocs,
          })
        );
        threads = docs;
      }
    } catch (error) {
      console.error("Error fetching threads", error);
    }
    ThreadHelper.hideLoader();
    store.dispatch(ThreadStoreActions.setAllThreads(threads));
  },
  loadNextPage: async () => {
    const { threadStore } = store.getState();
    const { searchText, pagination } = threadStore;
    const { page, limit, totalPages } = pagination;

    if (page >= totalPages) {
      return;
    }

    let threads = [];
    try {
      ThreadHelper.showLoader();
      const response = await ApiCalls.thread.private.getThreads({
        searchText,
        page: page + 1,
        limit,
      });
      if (response?.data?.data) {
        const { docs, totalDocs, limit, page, totalPages, hasNextPage } =
          response.data.data;
        store.dispatch(
          ThreadStoreActions.setPagination({
            limit,
            totalPages,
            page,
            hasNextPage,
            totalDocs,
          })
        );
        store.dispatch(ThreadStoreActions.setAllThreads(docs));
      }
    } catch (error) {
      console.error("Error fetching threads", error);
    }
    ThreadHelper.hideLoader();
    store.dispatch(ThreadStoreActions.setAllThreads(threads));
  },
  checkForThreadUpdates: async () => {
    const { threadStore } = store.getState();
    const { allThreads, searchText } = threadStore;
    let playNotification = false; // Flag to decide if we need to play the sound

    try {
      const response = await ApiCalls.thread.private.getThreads({
        searchText,
        page: 1,
        limit: 50,
      });
      if (response?.data?.data) {
        const updatedThreads = response.data.data.docs;

        // Compare the new threads with the existing threads in the store
        const mergedThreads = allThreads.map((existingThread) => {
          const updatedThread = updatedThreads.find(
            (thread) => thread._id === existingThread._id
          );

          // Check if the unread count increased
          if (
            updatedThread &&
            updatedThread.unreadMessages > existingThread.unreadMessages
          ) {
            playNotification = true;
          }

          // Merge only if the updated thread exists
          return updatedThread
            ? { ...existingThread, ...updatedThread }
            : existingThread;
        });

        // Check if new threads are added
        const newThreads = updatedThreads.filter(
          (thread) => !allThreads.some((t) => t._id === thread._id)
        );

        if (newThreads.length > 0) {
          playNotification = true;
        }

        // Update the store with merged threads and new threads
        store.dispatch(
          ThreadStoreActions.setAllThreads([...newThreads, ...mergedThreads])
        );

        // Play the notification sound if needed
        if (playNotification) {
          const notificationSound = new Audio(wavFile);
          notificationSound.play();
        }

        // Also if the selected thread has new messages, mark it as read
        const { selectedThread } = threadStore;
        if (selectedThread && selectedThread._id) {
          const updatedThread = updatedThreads.find(
            (thread) => thread._id === selectedThread._id
          );
          if (
            updatedThread &&
            updatedThread.unreadMessages > selectedThread.unreadMessages
          ) {
            ThreadHelper.markThreadAsRead(selectedThread._id);
          }
        }
      }
    } catch (error) {
      console.error("Error checking for thread updates", error);
    }
  },

  createNewThread: async ({ title, contactId }) => {
    try {
      ThreadHelper.showLoader();
      const response = await ApiCalls.thread.private.createThread({
        type: "individual",
        title,
        _contacts: [contactId],
        _users: [],
      });
      if (response?.data?.data) {
        ThreadHelper.loadThreadsList();
      }
    } catch (error) {
      console.error("Error creating thread", error);
    }
    ThreadHelper.hideLoader();
  },
  makeThreadActive: async (thread) => {
    // Only update in the store
    store.dispatch(ThreadStoreActions.setSelectedThread(thread));
    // Load thread messages
    MessageHelper.loadThreadMessages(thread._id);
    ThreadHelper.markThreadAsRead(thread._id);
  },
  makeThreadInactive: async () => {
    // Only update in the store
    store.dispatch(ThreadStoreActions.setSelectedThread(null));
  },
  markThreadAsRead: async (threadId) => {
    try {
      await ApiCalls.thread.private.markThreadAsRead(threadId);
      // Update the thread in the store
      const { threadStore } = store.getState();
      const { allThreads } = threadStore;
      const updatedThreads = allThreads.map((thread) =>
        thread._id === threadId ? { ...thread, unreadMessages: 0 } : thread
      );
      store.dispatch(ThreadStoreActions.setAllThreads(updatedThreads));
    } catch (error) {
      console.error("Error marking thread as read", error);
    }
  },
  updateThreadMessagePreview: async (threadId, message) => {
    // First call the API to update the message preview
    await ApiCalls.thread.private.updateThread(threadId, {
      lastMessagePreview: message,
    });
    // Then update the store locally
    const { threadStore } = store.getState();
    const { allThreads } = threadStore;
    const updatedThreads = allThreads.map((thread) =>
      thread._id === threadId
        ? { ...thread, lastMessagePreview: message }
        : thread
    );
    store.dispatch(ThreadStoreActions.setAllThreads(updatedThreads));
  },
  deleteThread: async (threadId) => {
    try {
      ThreadHelper.showLoader();
      await ApiCalls.thread.private.deleteThread(threadId);
      //    Also remove the thread from the store
      const { threadStore } = store.getState();
      const { allThreads } = threadStore;
      const updatedThreads = allThreads.filter(
        (thread) => thread._id !== threadId
      );
      store.dispatch(ThreadStoreActions.setAllThreads(updatedThreads));
      ThreadHelper.loadThreadsList();
    } catch (error) {
      console.error("Error deleting thread", error);
    }
    ThreadHelper.hideLoader();
  },
  setActiveThreadsListView: async (viewType) => {
    store.dispatch(ThreadStoreActions.setThreadListActiveView(viewType));
  },
  setSearchText: async (searchText) => {
    store.dispatch(ThreadStoreActions.setSearchText(searchText));
    ThreadHelper.loadThreadsList();
  },
  showLoader: () => {
    store.dispatch(ThreadStoreActions.setLoaderActive(true));
  },
  hideLoader: () => {
    store.dispatch(ThreadStoreActions.setLoaderActive(false));
  },
  getFormattedDateTime: (dateTime, showTimeAlways = false) => {
    // Ensure moment parses the date string correctly
    const inputDate = moment(dateTime, moment.ISO_8601, true);
    const now = moment();

    // If the date is invalid, return an error message
    if (!inputDate.isValid()) {
      return "Invalid Date";
    }

    // Check if the input date is today
    if (inputDate.isSame(now, "day")) {
      return inputDate.format("hh:mm A");
    }

    // Check if the input date is within the last 7 days
    if (now.diff(inputDate, "days") <= 6) {
      return inputDate.calendar(null, {
        sameDay: "[Today]",
        lastDay: "[Yesterday]",
        lastWeek: showTimeAlways ? "dddd hh:mm A" : "dddd",
        sameElse: "DD/MMM/YYYY",
      });
    }

    // Otherwise, return the date in DD/MMM/YYYY format, optionally with time if showTimeAlways is true
    return showTimeAlways
      ? inputDate.format("DD/MMM/YYYY hh:mm A")
      : inputDate.format("DD/MMM/YYYY");
  },

  sendMessage: async ({ messageText }) => {
    // Then update the store from the response
    const { threadStore } = store.getState();
    const { selectedThread } = threadStore;
    if (!selectedThread) {
      return;
    }
    const response = await ApiCalls.message.private.sendMessage({
      threadId: selectedThread._id,
      message: messageText,
      messageType: "messages",
    });
    // Trigger a reload of the messages
    ThreadHelper.updateThreadMessagePreview(
      selectedThread._id,
      `You: ${messageText}`
    );
    await MessageHelper.checkNewMessages(selectedThread._id);
  },
};

export default ThreadHelper;

import "./App.css";
import AppRoutes from "./routing/app-routes";
import TopLevelProviders from "./shared/top-level-providers/top-level-providers";

function App() {
  return (
    <div className="App">
      <TopLevelProviders>
        <AppRoutes />
      </TopLevelProviders>
    </div>
  );
}

export default App;

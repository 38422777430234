// user-api.js
import {
  ProtectedHttpClient,
  PublicHttpClient,
} from "../shared/clients/http-client";

const OrganizationApi = {
  public: {},
  private: {
    addNewUser: (payload) => ProtectedHttpClient.post("/organization/invite", payload), // Requires token
    getAllUsers: () => ProtectedHttpClient.post("/users"), // Requires token
  },
};

export default OrganizationApi;

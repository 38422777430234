import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the message
const initialState = {
  isLoaderActive: false,
  messages: [],
  pagination: {
    limit: 50,
    totalPages: 1,
    page: -1,
    hasNextPage: true,
  },
};

// Create a slice for user-related state
const messageSlice = createSlice({
  name: "messageStore",
  initialState,
  reducers: {
    setLoaderActive: (state, action) => {
      state.isLoaderActive = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
  },
});

// Export the actions directly for usage without dispatching manually
const { setLoaderActive, setMessages, setPagination } = messageSlice.actions;

// Create a MessageStore object that automatically dispatches actions
export const MessageStoreActions = {
  setLoaderActive,
  setMessages,
  setPagination,
};

// Export the reducer to be used in the store
export const MessageStoreReducer = messageSlice.reducer;

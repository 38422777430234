import ApiCalls from "../../../api";
import { store } from "../../../redux/store";

const ManageMemberUtils = {
  fetchAllMembers: async () => {
    const response = await ApiCalls.organization.private.getAllUsers();
    return response?.data?.data?.docs || [];
  },
  addNewMember: async (newMember) => {
    const {userStore} = store.getState();
    const payload = {
      ...newMember,
      _organizations: [userStore.userInfo._organizations[0]],
    }
    const response = await ApiCalls.organization.private.addNewUser(payload);
    return response?.data?.data;
  },
  updateMember: async (member) => {
    const memberId = member._id;
    // delete password field if it''s exist
    if (member.password) {
      delete member.password;
    }

    const response = await ApiCalls.user.private.updateUser(memberId, member);
    return response?.data?.data;
  },
};

export default ManageMemberUtils;

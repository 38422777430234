import ApiCalls from "../../api";
import { store } from "../../redux/store";
import { TemplateStoreActions } from "../../redux/template-store";

const TemplateHelper = {
  loadTwilioTemplates: async () => {
    // Get user from store
    const user = store.getState().userStore.userInfo;
    if (user._organizations?.length) {
      const orgId = user._organizations[0];
      try {
        const templates = await ApiCalls.message.private.getAllTemplates(orgId);
        store.dispatch(TemplateStoreActions.setTemplates(templates?.data?.data || []));
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
  },
};

export default TemplateHelper;

import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the message
const initialState = {
  isLoaderActive: false,
  templates: [],
};

const templateSlice = createSlice({
  name: "templateStore",
  initialState,
  reducers: {
    setLoaderActive: (state, action) => {
      state.isLoaderActive = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
  },
});

// Export the actions directly for usage without dispatching manually
const { setLoaderActive, setTemplates } = templateSlice.actions;

// Create a TemplateStore object that automatically dispatches actions
export const TemplateStoreActions = {
  setLoaderActive,
  setTemplates,
};

// Export the reducer to be used in the store
export const TemplateStoreReducer = templateSlice.reducer;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./manage-users.scss";
import AddEditUserModal from "../../components/add-edit-user-modal/add-edit-user-modal";
import DeleteUserConfirmationModal from "../../components/delete-user-confirmation-modal/delete-user-confirmation-modal";
import ManageMemberUtils from "../../utils/manage-member-utils";

const ManageUsersPage = () => {
  const { userInfo } = useSelector((state) => state.userStore);

  const [users, setUsers] = useState([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const refreshUsers = async () => {
    const response = await ManageMemberUtils.fetchAllMembers();
    setUsers(response);
  };

  useEffect(() => {
    refreshUsers();
  }, []);

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowAddEditModal(true);
  };

  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const confirmDeleteUser = async () => {
    if (selectedUser) {
      const updatedUser = { ...selectedUser, status: "deleted" };
      await ManageMemberUtils.updateMember(updatedUser);
      refreshUsers();
      setShowDeleteModal(false);
      setSelectedUser(null);
    }
  };

  const toggleUserStatus = async (user) => {
    const updatedUser = {
      ...user,
      status: user.status === "active" ? "inactive" : "active",
    };
    await ManageMemberUtils.updateMember(updatedUser);
    refreshUsers();
  };

  const handleCloseAddEditModal = () => {
    setShowAddEditModal(false);
    setSelectedUser(null);
  };

  return (
    <>
      <div className="container-fluid">{/* Page-Title */}</div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <button
                className="btn btn-primary btn-sm px-4"
                onClick={() => {
                  setSelectedUser(null);
                  setShowAddEditModal(true);
                }}
              >
                + Add New
              </button>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="thead-light">
                    <tr>
                      <th>Email</th>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Contact No</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user._id}>
                        <td>{user.email}</td>
                        <td>{user.name || "--"}</td>
                        <td>{user.role}</td>
                        <td>
                          <span
                            className={`badge ${
                              user.status === "active"
                                ? "badge-soft-success"
                                : "badge-soft-danger"
                            }`}
                          >
                            {user.status.charAt(0).toUpperCase() +
                              user.status.slice(1)}
                          </span>
                        </td>
                        <td>{user.phone || "--"}</td>
                        <td>
                          {/* Conditionally hide actions if it's the logged-in user */}
                          {user.email !== userInfo.email && (
                            <>
                              <button
                                className="btn btn-soft-primary btn-sm me-1"
                                onClick={() => handleEditUser(user)}
                              >
                                Edit
                              </button>
                              <button
                                className="btn btn-soft-secondary btn-sm me-1"
                                onClick={() => toggleUserStatus(user)}
                              >
                                {user.status === "active"
                                  ? "Deactivate"
                                  : "Activate"}
                              </button>
                              <button
                                className="btn btn-soft-danger btn-sm"
                                onClick={() => handleDeleteUser(user)}
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddEditUserModal
        show={showAddEditModal}
        onClose={handleCloseAddEditModal}
        user={selectedUser}
        onUserUpdated={refreshUsers}
      />
      <DeleteUserConfirmationModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={confirmDeleteUser}
        userName={selectedUser?.name}
      />
    </>
  );
};

export default ManageUsersPage;

import React from "react";
import ThreadHelper from "../../../../entities/thread/thread-helper";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import Events from "../../../../const/events";

const ChatMessage = ({ message, selectedThread, direction }) => {
  const _showQRPopup = () => {
    EventEmitter.dispatch(Events.SHOW_QR_CODE_POPUP, {
      id: message?._by?._id,
      title: message?._by?.name,
    });
  };
  if (message.msgType !== "messages") {
    return (
      // <div className="media sending-loader">
      <div className="media ">
        <div className="media-body reverse">
          <div className="chat-msg">
            <p>
              {message.message}
              <span className="sender">Doxa bot</span>
              <span className="timestamp">
                {ThreadHelper.getFormattedDateTime(message.createdAt, true)}

                <span className="status">
                  {message.status == "read" ? (
                    <span className="read" title="Read at 12:00 pm">
                      <i className="fa fa-check-double"></i>
                    </span>
                  ) : (
                    <span className="notRead" title="Delivered">
                      <i className="fa fa-check"></i>
                    </span>
                  )}
                </span>
              </span>
            </p>
          </div>
          {/* <div className="chat-msg">
        <p>
          There are many variations of passages of Lorem Ipsum available.
          <span className="timestamp">2:30pm</span>
          
        </p>
      </div> */}
        </div>
        <div className="media-img">
          <img
            src="assets/images/ai.jpg"
            alt="user"
            className="rounded-circle thumb-sm"
          />
        </div>
      </div>
    );
  }
  return (
    <>
      {direction === "outgoing" ? (
        // <div className="media sending-loader">
        <div className="media ">
          <div className="media-body reverse">
            <div className="chat-msg">
              <p>
                {message.message}
                <span className="sender">{message._by.email}</span>
                <span className="timestamp">
                  {ThreadHelper.getFormattedDateTime(message.createdAt, true)}

                  <span className="status">
                    {message.status == "read" ? (
                      <span className="read" title="Read at 12:00 pm">
                        <i className="fa fa-check-double"></i>
                      </span>
                    ) : (
                      <span className="notRead" title="Delivered">
                        <i className="fa fa-check"></i>
                      </span>
                    )}
                  </span>
                </span>
              </p>
            </div>
            {/* <div className="chat-msg">
              <p>
                There are many variations of passages of Lorem Ipsum available.
                <span className="timestamp">2:30pm</span>
                
              </p>
            </div> */}
          </div>
          <div className="media-img">
            <img
              src="assets/images/profile-avatar.png"
              alt="user"
              className="rounded-circle thumb-sm"
            />
          </div>
        </div>
      ) : (
        <div className="media">
          <div className="media-img">
            <div className="avatar-box thumb-sm align-self-center">
              <span
                className="thumb-sm justify-content-center d-flex align-items-center bg-soft-primary rounded-circle"
                onClick={_showQRPopup}
              >
                {message?._by?.name ? message?._by?.name[0] : "U"}
              </span>
            </div>
          </div>
          <div className="media-body">
            <div className="chat-msg">
              <p>
                {message.message}{" "}
                <span className="timestamp">
                  {ThreadHelper.getFormattedDateTime(message.createdAt, true)}
                </span>
              </p>
            </div>
            {/* <div className="chat-msg">
              <p>
                There are many variations of passages of Lorem Ipsum available.
                <span className="timestamp">2:30pm</span>
              </p>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ChatMessage;

import ApiCalls from "../../api";
import { MessageStoreActions } from "../../redux/message-store";
import { store } from "../../redux/store";

const MessageHelper = {
  loadThreadMessages: async (threadId) => {
    // Reset pagination and Load all messages from a thread and
    //  Clear the messages in the store
    store.dispatch(MessageStoreActions.setMessages([]));
    try {
      MessageHelper.showLoader();
      const response = await ApiCalls.message.private.getMessages({
        threadId,
        page: 1,
        limit: 50,
      });
      if (response?.data?.data) {
        const { docs, totalDocs, limit, page, totalPages, hasNextPage } =
          response.data.data;
        store.dispatch(
          MessageStoreActions.setPagination({
            limit,
            totalPages,
            page,
            hasNextPage,
            totalDocs,
          })
        );
        // Reverse the order of messages
        // docs.reverse();
        store.dispatch(MessageStoreActions.setMessages(docs));
      }
    } catch (error) {
      console.error("Error fetching messages", error);
    }
    MessageHelper.hideLoader();
  },
  loadNextPage: async () => {
    // Load next page of messages from a thread
    const { messageStore, threadStore } = store.getState();
    const { pagination, isLoaderActive } = messageStore;
    const { page, limit, totalPages } = pagination;

    if (page >= totalPages || isLoaderActive) {
      return;
    }

    try {
      MessageHelper.showLoader();
      const response = await ApiCalls.message.private.getMessages({
        threadId: threadStore.selectedThread._id,
        page: page + 1,
        limit,
      });
      if (response?.data?.data) {
        const { docs, totalDocs, limit, page, totalPages, hasNextPage } =
          response.data.data;
        store.dispatch(
          MessageStoreActions.setPagination({
            limit,
            totalPages,
            page,
            hasNextPage,
            totalDocs,
          })
        );
        // Reverse the order of messages
        // docs.reverse();
        store.dispatch(
          MessageStoreActions.setMessages([...messageStore.messages, ...docs])
        );
      }
    } catch (error) {
      console.error("Error fetching messages", error);
    }
    MessageHelper.hideLoader();
  },
  checkNewMessages: async (threadId) => {
    // Check for new messages and update existing ones
    const { messageStore } = store.getState();
    const existingMessages = messageStore.messages || [];

    try {
      const response = await ApiCalls.message.private.getMessages({
        threadId,
        page: 1,
        limit: 50, // Fetching only the latest messages
      });
      if (response?.data?.data) {
        const { docs: newMessages } = response.data.data;

        const updatedMessages = existingMessages.map((existingMsg) => {
          const updatedMessage = newMessages.find(
            (newMsg) => newMsg._id === existingMsg._id
          );
          return updatedMessage
            ? { ...existingMsg, ...updatedMessage }
            : existingMsg;
        });

        const newOnlyMessages = newMessages.filter(
          (newMsg) => !existingMessages.some((msg) => msg._id === newMsg._id)
        );

        // Update the store with new messages and updated statuses
        store.dispatch(
          MessageStoreActions.setMessages([
            ...newOnlyMessages,
            ...updatedMessages,
          ])
        );
      }
    } catch (error) {
      console.error("Error checking for new messages", error);
    }
  },

  sendMessage: async ({ threadId, messageText }) => {
    // Send a message to a thread
    try {
      MessageHelper.showLoader();
      const response = await ApiCalls.message.private.sendMessage({
        threadId,
        messageText,
      });
      if (response?.data?.data) {
        const { message } = response.data.data;
        store.dispatch(
          MessageStoreActions.setMessages([
            ...store.getState().messageStore.messages,
            message,
          ])
        );
      }
    } catch (error) {
      console.error("Error sending message", error);
    }
    MessageHelper.hideLoader();
  },
  showLoader: () => {
    store.dispatch(MessageStoreActions.setLoaderActive(true));
  },
  hideLoader: () => {
    store.dispatch(MessageStoreActions.setLoaderActive(false));
  },
  sendTemplateMessage: async (template, thread) => {
    // Send a template message to a thread
    try {
      MessageHelper.showLoader();
      const response = await ApiCalls.message.private.sendTemplateMessage({
        threadId: thread._id,
        payload: {
          templateSid: template.sid,
          msgType: 'marketing',
        },
      });
      // if (response?.data?.data) {
      //   const { message } = response.data.data;
      //   store.dispatch(
      //     MessageStoreActions.setMessages([
      //       ...store.getState().messageStore.messages,
      //       message,
      //     ])
      //   );
      // }
    } catch (error) {
      console.error("Error sending template message", error);
    }
    MessageHelper.hideLoader();
  },
};

export default MessageHelper;

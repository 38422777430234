// firebase-provider.jsx
import React, { useState, useEffect } from "react";
import FirebaseAuthHelper from "../../../features/authentication/helpers/firebase-auth-helper/firebase-auth-helper";

const FirebaseProvider = ({ children }) => {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  useEffect(() => {
    // Initialize Firebase when the component mounts
    FirebaseAuthHelper.initFirebase();
    // Set firebaseInitialized to true after Firebase is initialized
    // Add a delay to simulate a network request
    setTimeout(async () => {
      // const token = await FirebaseAuthHelper.getFirebaseAuthToken();
      setFirebaseInitialized(true);
    }, 1000);
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  if (!firebaseInitialized) {
    return <div>Loading Firebase...</div>;
  }

  return (
    <>
      {children} {/* Render children only after Firebase is initialized */}
    </>
  );
};

export default FirebaseProvider;

// PublicRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import FirebaseAuthHelper from "../../features/authentication/helpers/firebase-auth-helper/firebase-auth-helper";

const PublicRoute = ({ children }) => {
  const user = FirebaseAuthHelper.getCurrentUser();

  console.log('user :>> ', user);

  if (user) {
    // If the user is authenticated, redirect to the dashboard
    return <Navigate to="/whatsapp-chats" />;
  }

  return children;
};

export default PublicRoute;

import FirebaseAuthHelper from "../firebase-auth-helper/firebase-auth-helper";
import { UserStoreActions } from "../../../../redux/user-store";
import { store } from "../../../../redux/store";
import ApiCalls from "../../../../api";
import router from "../../../../shared/top-level-declarations/history";

const AuthHelper = {
  // Login method that handles Firebase authentication and token exchange with backend
  login: async (email, password) => {
    try {
      // Step 1: Dispatch login request action to update the loading state
      store.dispatch(UserStoreActions.loginRequest());

      // Step 2: Firebase login using email and password
      const firebaseUser = await FirebaseAuthHelper.login({ email, password });

      // Step 3: Get Firebase ID token to exchange for access and refresh tokens
      const firebaseToken = await firebaseUser.getIdToken();

      // Step 4: Send the Firebase token to your backend to get access and refresh tokens
      const response = await ApiCalls.user.public.login(firebaseToken);
      const { user, accessToken, refreshToken } = response.data.data; // Extract user, accessToken, and refreshToken
      console.log('response :>> ', response);
      // Step 5: Dispatch success action to store user info, accessToken, and refreshToken
      store.dispatch(
        UserStoreActions.loginSuccess({
          user, // Store user info
          accessToken, // Store access token
          refreshToken, // Store refresh token
        })
      );
      return user; // Return user data if needed
    } catch (error) {
      // Step 6: Dispatch failure action and pass the error message
      store.dispatch(UserStoreActions.loginFailure(error.message));
      throw error; // Re-throw the error for handling in the component
    }
  },

  // Method to refresh the access token using the refresh token
  refreshToken: async () => {
    // const state = store.getState();
    // const refreshToken = state.userStore?.refreshToken;

    // if (!refreshToken) {
    //   throw new Error("No refresh token available");
    // }

    // // Call API to refresh access token using the refresh token
    // const response = await ApiCalls.user.public.refreshToken(refreshToken);
    // const { user, accessToken, refreshToken: newRefreshToken } = response.data; // Extract new tokens and user info

    // // Update Redux state with new tokens and user info
    // store.dispatch(
    //   UserStoreActions.loginSuccess({
    //     user, // Update user info (if needed)
    //     accessToken, // Update access token
    //     refreshToken: newRefreshToken, // Update refresh token
    //   })
    // );

    const firebaseToken = await FirebaseAuthHelper.getFirebaseAuthToken();

      // Step 4: Send the Firebase token to your backend to get access and refresh tokens
      const response = await ApiCalls.user.public.login(firebaseToken);
      const { user, accessToken, refreshToken } = response.data.data; // Extract user, accessToken, and refreshToken
      console.log('response :>> ', response);
      // Step 5: Dispatch success action to store user info, accessToken, and refreshToken
      store.dispatch(
        UserStoreActions.loginSuccess({
          user, // Store user info
          accessToken, // Store access token
          refreshToken, // Store refresh token
        })
      );
    return accessToken; // Return the new access token
  },

  // Logout method that handles Firebase logout and Redux state update
  logout: async () => {
    try {
      await FirebaseAuthHelper.logout(); // Call Firebase logout
      store.dispatch(UserStoreActions.logout()); // Dispatch logout to store
      router.navigate("/login"); // Navigate to login page
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  },

  // Clear error method for resetting error states in Redux
  clearError: () => {
    store.dispatch(UserStoreActions.clearError());
  },

  // Method to get the current user authentication state from Redux
  getAuthState: () => {
    const state = store.getState();
    return state.userStore; // Return the user store state
  },

  getAccessToken: () => {
    const state = store.getState();
    return state.userStore?.accessToken; // Return the access token
  },
};

export default AuthHelper;

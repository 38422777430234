import ApiCalls from "../../api";

const ContactHelper = {
  createNewContact: async ({ name, phone }) => {
    const response = await ApiCalls.contact.private.createContact({
      of: "unknown",
      platforms: [
        {
          referenceId: `whatsapp:${phone}`,
          on: "whatsapp",
        },
      ],
      name,
      phone,
    });
    return {
      contactId: response?.data?.data?._id || null,
    };
  },
};

export default ContactHelper;

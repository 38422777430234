// thread-api.js
import { ProtectedHttpClient } from "../shared/clients/http-client";

const ThreadApi = {
  private: {
    getThreads: ({ page, limit, searchText }) =>
      ProtectedHttpClient.post(`/threads?page=${page}&limit=${limit}`, {
        search: searchText,
      }), // Requires token
    createThread: (data) => ProtectedHttpClient.post("/thread", data), // Requires token
    markThreadAsRead: (threadId) =>
      ProtectedHttpClient.post(`/thread/${threadId}/mark-read`), // Requires token
    updateThread: (threadId, data) =>
      ProtectedHttpClient.patch(`/thread/${threadId}`, data), // Requires token
  },
};

export default ThreadApi;
